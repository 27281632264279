.custom_toggle_switch.p-inputswitch {
    width: 3rem;
    height: 1.5rem;
    &.p-inputswitch-checked .p-inputswitch-slider {
        background: var(--primary-color);
        &::before {
            width: 1.25rem;
            height: 1.25rem;
            transform: translateX(1.406rem);
        }
    }
}
