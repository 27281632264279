$theme-map-light: (
  --color-primary: #e41032,
  --color-secondary: #f2f2f2,
  --color-background: #fafafa,
  --color-dark: #000,
  --color-light: var(--surface-ground),
  --color-border: #dedede,
  --color-btn-text: var(--surface-ground),
  --color-error: #ff0000,
);

$theme-map-dark: (
  // for colors
  --surface-ground: #040d19,
  --surface-section: #091a32,
  --surface-overlay: #071426,
  --surface-card: #071426,
  --surface-border: #0b213f,
  --primary-color: #93c5fd,
  --text-color-tag: #6cb0fc,
  --secondary-color: #cbd5e1,
  --primary-color-text: #1c2127,
  --surface-hover: rgba(147, 197, 253, 0.16),
  --text-color: rgba(255, 255, 255, 0.87),
  --surface-gray-hover: rgba(255, 255, 255, 0.03),
  --text-color-secondary: #919191,
  --color-error: rgba(248, 113, 113),
  --color-error-light: #eb9a9c,
  --color-light-bg: rgba(0, 0, 0, 0.4),
  --color-white-text: #ffffff,
  --color-white-text-1: #fff5f5,
  --color-red: red,
  --color-grey-font: #cbd5e1,
  --color-lara-red: #ff6259,
  --color-lara-yellow: #f6de95,
  --color-lara-green: #4cd07d,
  --no-color: #07142600,
  --blue-font: #93c5fd,
  --button-white-color: #e4f8f0,
  --button-white-text-color: #1ea97c,
  --label-bg: #0b213f,
  --tree-child-one: rgba(147, 197, 253, 0.16),
  --tree-child-two: #295bac,
  --tree-child-three: #1a51a6,
  --tree-child-four: #1f67d9,
  --tree-child-color: #1f67d9,
  --tree-child-hover-color: rgba(147, 197, 253, 0.16),
  --tree-child-fifth: rgba(147, 197, 253, 0.16),
  --notification-text: #828289,
  --notification-list: #afafaf,
  --textbox-focus-color: rgba(255, 255, 255, 0.6),
  --left-menu-collapse: #183462,
  --multi-dropdown-border: rgba(0, 0, 0, 0.13),
  --table-header-hover: #021020,
  --image-bg: #081b35,
  --card-bg-primary: #0a1b31,
  --card-bg-secondary: #0f2646,
  --btn-stop-primary: #d9342b,
  --bg-file-upload: #060d18,
  --bg-file-upload-comment: #0b1f39,
  --bg-file-upload-avatar: #274b79,
  // for font size
  --font-small-hover: 0.8rem,
  --font-small: 0.656rem,
  // 10.5px
  --font-mid: 0.75rem,
  // 12px
  --font-body: 0.875rem,
  --font-btn: 0.8rem,
  // 14px
  --font-h5: 1.1rem,
  // 17px
  --font-h6: 1rem,
  // 20px
  --font-20: 1.25rem,
  // 24px
  --font-24: 1.5rem,
  // 28px
  --font-28: 1.75rem,
  //32.36px
  --font-32: 2.0225rem,
  // 31.6px
  --font-semi-large: 1.975rem,
  //35px
  --font-large: 2.1875rem,
  // 37.93
  --font-extra-large: 2.37rem,
  // for font weight
  --regular-400: 400,
  --semibold-600: 600,
  --semibold-700: 700,
  --bold-700: 700,

  // for line height
  --line-height-100: 100%,
  --line-height-120: 120%,
  --line-height-150: 150%,

  //width in vw
  --width-70vw: 70vw,
  --width-25vw: 25vw,
  --width-15vw: 15vw,
  --width-90: 90%,
  --width-10: 10%,
  --width-80: 80%,
  --size-1rem: 1rem,
  --size-1p5rem: 1.5rem,
  --size-2rem: 2rem,
  --size-2p5rem: 2.5rem,
  --size-075rem: 0.75rem,
  --size-02rem: 0.2rem,
  --size-01rem: 0.1rem
);
