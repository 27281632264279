.form-label {
    font-size: var(--font-h6);
    line-height: var(--line-height-150);
    color: var(--text-color);
}

.form-error-message {
    margin-top: 3px;
    font-size: var(--font-body);
    // position: absolute;
    color: var(--color-error);
}

.form-control-input,
.form-control-select,
.form-control-text-area {
    width: 100%;
    padding: 10.5px;
    font-size: var(--font-body);
    line-height: var(--line-height-150);
    background: var(--surface-ground);
    border: 1px solid var(--surface-border);
    &.mobile_with_dial_code {
        padding-left: 4.375rem;
    }
    &.full_opacity_in_disable {
        &.p-disabled {
            opacity: 1;
        }
    }
    &.value_only {
        padding: 0;
        border: none;
        background: transparent;
        font-weight: var(--bold-700);
        & .p-dropdown-label,
        &.p-dropdown .p-dropdown-clear-icon,
        &.p-dropdown .p-dropdown-trigger {
            color: var(--primary-color);
        }
        &.no_clear_icon.p-dropdown .p-dropdown-trigger {
            width: fit-content;
        }
    }
    &.left__icon {
        padding-left: 2.5rem;
    }
    & .p-inputtext {
        font-size: var(--font-body);
        line-height: var(--line-height-150);
        padding: 0 0.75rem 0 0;
        &:enabled:focus {
            outline: none;
            box-shadow: none;
        }
    }
    &.p-multiselect .p-multiselect-label,
    & .p-dropdown .p-dropdown-trigger {
        padding: 0;
    }
}

.form-control-text-area {
    height: 10rem;
    &.disabled {
        opacity: 0.5;
        box-shadow: none !important;
        border-color: transparent !important;
    }
}

.form-control-date-picker {
    width: 100%;
    border: 1px solid transparent;
    border-radius: 6px;
    & .p-inputtext {
        padding: 10.5px;
        font-size: var(--font-body);
        line-height: var(--line-height-150);
        border-right: none;
        &:hover {
            border-color: var(--surface-border);
        }
        &:focus {
            box-shadow: none;
            border-color: var(--surface-border);
        }
        &:disabled {
            border-color: transparent;
        }
    }
    & .p-button {
        padding: 10.5px;
        background: var(--surface-ground);
        color: var(--text-color-secondary);
        border: 1px solid var(--surface-border);
        border-left: none;
        &:hover {
            background: var(--surface-ground);
            color: var(--text-color-secondary);
            border: 1px solid var(--surface-border);
            border-left: none;
        }
        &:active {
            border: 1px solid var(--surface-border);
            border-left: none;
        }
        &:focus {
            box-shadow: none;
        }
    }
    &:hover {
        border: 1px solid var(--primary-color);
        &.p-calendar-disabled {
            border-color: transparent;
        }
    }
    &:focus {
        outline: 0 none;
        outline-offset: 0;
        border: 1px solid var(--primary-color);
        box-shadow: var(--focus-ring);
        &.p-calendar-disabled {
            border-color: transparent;
        }
    }
}

.mobile_with_dial_code.p-float-label label {
    padding-left: 4.375rem;
}

.form-control-text-area.intervention-comments {
    max-width: 100%;
    resize: vertical;
    min-height: 40px;
}

.form-control-text-area.intervention-textbox {
    max-width: 100%;
    min-height: 40px;
}
